<template lang="html">
  <transition mode="out-in">
    <span v-if="loading">Loading...</span>
    <div v-else>
      <EcologyInputGrid>
        <DlgGridHeader>
          1. <IxRes>dlgCert.ecology.soilHealth.headers.agriculture</IxRes>
        </DlgGridHeader>

        <EcologyInputLine
          :data="data" name="catchCropCultivation"
          show-percent-of="cultivated"
        />
        <EcologyInputLine
          :data="data" name="undersownCrop"
          show-percent-of="cultivated"
          locked
        />
        <EcologyInputLine
          :data="data" name="leguminous"
          show-percent-of="cultivated"
        />
        <EcologyInputLine
          :data="data" name="perennialCrops"
          show-percent-of="cultivated"
          @input="update"
        />
        <DlgGridHeader>
          2. <IxRes>dlgCert.ecology.soilHealth.headers.other</IxRes>
        </DlgGridHeader>

        <DlgGridHeader>
          <IxRes>dlgCert.ecology.soilHealth.headers.heavyMachinery</IxRes>
        </DlgGridHeader>
        <EcologyInputLine
          :data="data" name="lightMachineryUse"
          locked unit=""
        />

        <DlgGridHeader>
          <IxRes>dlgCert.ecology.soilHealth.headers.technology</IxRes>
        </DlgGridHeader>
        <DlgGridCheckboxLine :data="data" name="controlledTrafficFarming" @input="update" />
        <DlgGridCheckboxLine :data="data" name="softTires" @input="update" />
        <DlgGridCheckboxLine :data="data" name="pressureControlledTires" @input="update" />

        <DlgGridHeader>
          <IxRes>dlgCert.ecology.soilHealth.headers.training</IxRes>
        </DlgGridHeader>
        <DlgGridCheckboxLine :data="data" name="training" @input="update" />

        <DlgGridHeader>
          <IxRes>dlgCert.ecology.soilHealth.headers.soilSampling</IxRes>
        </DlgGridHeader>
        <EcologyInputLine
          :data="data" name="precisionSampledFields"
          unit=""
          @input="update"
        />
        <EcologyInputLine
          :data="data" name="moderatelyNeutralPhFields"
          unit=""
          @input="update"
        />

        <DlgGridHeader>
          <IxRes>dlgCert.ecology.soilHealth.headers.spadeTesting</IxRes>
        </DlgGridHeader>
        <EcologyInputLine
          :data="data"
          name="spadeTestedFields"
          unit=""
          show-percent-of="cultivatedCount"
          @input="update"
        />
      </EcologyInputGrid>
    </div>
  </transition>
</template>

<script>
import EcologyInputGrid from './elements/EcologyInputGrid'
import DlgGridHeader from './elements/DlgGridHeader'
import EcologyInputLine from './elements/EcologyInputLine'
import DlgGridCheckboxLine from './elements/DlgGridCheckboxLine'

import ApiMixin from '@dlg-cert/mixins/ApiMixin'

export default {
  components: {
    EcologyInputGrid,
    DlgGridHeader,
    EcologyInputLine,
    DlgGridCheckboxLine
  },
  mixins: [
    ApiMixin
  ],
  computed: {
    apiUrl () {
      return `/api/v2/dlg-cert/${this.$route.params.orgUnitId}/ecology/${this.$route.params.year}/soilHealth`
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
